// M XiangHe Hei TC W05 Thin
@font-face {
  font-family: 'MXiangHeHK';
  src: url('#{$netstorage-fonts-path}cjk/M_XiangHe_HK/166286c7-fbe8-4b63-a19f-0a4daa61e840.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'MXiangHeHK';
  src: url('#{$netstorage-fonts-path}cjk/M_XiangHe_HK/166286c7-fbe8-4b63-a19f-0a4daa61e840.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

// M XiangHe Hei TC W05 Medium
@font-face {
  font-family: 'MXiangHeHK';
  src: url('#{$netstorage-fonts-path}cjk/M_XiangHe_HK/0bfd148c-fb4c-4ff4-91d5-becf93303482.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'MXiangHeHK';
  src: url('#{$netstorage-fonts-path}cjk/M_XiangHe_HK/0bfd148c-fb4c-4ff4-91d5-becf93303482.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

html,
body {
  font-family: $hk-primary-font;
}